<template>
  <div class="home">
    <!-- AppBar -->
    <v-app-bar color="teal lighten-3" dark clipped-left app class="no-print">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{
        $store.state.title ? $store.state.title : "スケジュール管理システム"
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- メインアバターチップ -->
      <v-chip class="ma-2" color="primary" text-color="white">
        <v-avatar v-if="$store.state.type === UserType.Office" left>
          <v-icon>mdi-account</v-icon>
        </v-avatar>
        <v-avatar v-else left class="blue darken-4">
          {{ $store.state.name.charAt(0) }}
        </v-avatar>
        {{ $store.state.name }}
      </v-chip>
    </v-app-bar>

    <!-- Drawer -->
    <v-navigation-drawer
      v-model="drawer"
      clipped
      absolute
      app
      fixed
      :temporary="$vuetify.breakpoint.mobile"
    >
      <ListMenu v-model="menus" />
    </v-navigation-drawer>

    <!-- Main -->
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <div class="Page-Btn" @click="scrollTop">
      <i class="fa fa-chevron-up Page-Btn-Icon"></i>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";

import UserType from "@/commons/usertype";

import TmpLogin from "./dialogs/TemporaryLogin";

import menus from "@/commons/menus";

export default {
  name: "Home",
  mixins: [Common, ShowDialogs],
  components: {
    ListMenu: () => import("@/components/ListMenu")
  },
  data() {
    return {
      UserType,
      drawer: false,
      menus
    };
  },
  methods: {
    logout() {
      console.log("logout");
    },
    scrollTop: function() {
      window.scrollTo({
        top: 0,
        behavior: "instant"
      });
    },
    click(e) {
      console.log(e);
    },
    async tmpLogin() {
      console.log(this.$store);
      if (!this.$store.state.user.login) await this.$showDialog(TmpLogin);
      else if (await this.$confirm("ログアウトしますか？"))
        this.$store.commit("user/logout");
    }
  },
  created() {
    console.log("vuetify.breakpoint", this.$vuetify.breakpoint);
    console.log(menus);
  }
};
</script>

<style>
.Page-Btn {
  position: fixed;
  right: 5px;
  bottom: 50px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background: #5bc8ac;
}
.Page-Btn-Icon {
  color: #fff;
  font-size: 20px;
}
</style>
